import { Chart, DoughnutController, ArcElement, Tooltip } from 'chart.js';
import { formatCurrency } from './utils/formatter';

Chart.register(DoughnutController, ArcElement, Tooltip);

const initPieChart = (ctx) => {
    new Chart(ctx, {
        type: 'doughnut',
        data: JSON.parse(ctx.dataset.data),
        options: {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function(context) {
                            let label = context.label || '';

                            if (label) {
                                label += ': ';
                            }

                            if (context.parsed !== null) {
                                label += formatCurrency(context.parsed);
                            }
                            return label;
                        },
                    },
                },
            },
        },
    });
};


document
    .addEventListener('DOMContentLoaded', () => document.querySelectorAll('.client-pie-chart')
        .forEach((ctx) => initPieChart(ctx)));
